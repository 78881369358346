.testimonials-section {
  padding-top: 3em;
  width: 100%;
  height: 70em;
  background-color: var(--fourth-color);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wrapper__swiper__testimonials {
  width: 39em;
  height: 40em;
  padding-top: 4em;
}

.swiper-testimonials {
  width: 100%;
  height: 100%;
  border-radius: 22px;
}

.swiper-testimonials .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  border-radius: 22px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .wrapper__swiper__testimonials {
    width: 94%;
    height: auto;
  }

  .section-testimonials {
    padding-top: 15em;
    height: 87em;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .wrapper__swiper__testimonials {
    width: 85%;
    height: 45em;
  }
}
