.card-services {
    width: 15em;
    height: 18em;
    background-color: var(--fourth-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
}
.cbody__wrapper__services {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
}
.img_card_services {
    width: 10em;
}
.cfooter__wrapper__services {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}
.label-card-services {
    word-wrap: break-word;
    text-align: center;
    color: #fff;
    font-weight: bold;
}
.contanier-btn {
    margin-top: 1em;
}

.container-card-services {
    display: flex;
    flex-direction: column;
}
