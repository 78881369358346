.wrapper__input {
    background-color: #fff;
    border: 2.3px solid var(--fourth-color);
    border-radius: 5px;
    display: flex;
    margin-bottom: 1em;   
}

.form__contact__item__label {
    padding-bottom: .5em;
    font-weight: bold;
    color: #356CB3;
    font-size: 1.2em;
}

.form-control-input {
    background-color: #fff;
    outline: none;
    border: none;
    width: 100%;
}
.form__contact__item__input {
    height: 2.3em;
}
.form__contact__item__textarea {
    min-height: 13em;
    max-height: 13em;
    padding-left: .8em;
}
.icon-input {
    width: 2.3em;
    height: 2.3em;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 768px) {
    .form__contact__item__textarea {
        min-height: 8em;
        max-height: 8em;
    }
}
    