.contact-us-section {
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container__wrapper__form {
    display: flex;
    padding: 2em 0em;
}
.wrapper__form_contact {
    width: 100%;
    margin: 5em 20%;
    background-color: var(--seventh-color);
}
.form__contact {
    width: 60%;
    padding: 0em 7em;
}
.container__contact-us {
    padding-top: 2em;
}
.img-contact {
    width: 2.5em;
    height: 2.5em;
    margin-right: 1em;
}
.img__label {
    color: #356CB3;
    font-size: 1.3em;
    font-weight: bold;
}
.row__container-us{
    margin-bottom: 1em;
}
.column__container-us {
    display: flex;
    align-items: center;
    padding-bottom: .1em;
}
.contact-label {
    padding-left: 1em;
    color: #356CB3;
    font-size: 1.2em;
}
.contact-label a{
    color: #356CB3;
    text-decoration: none;
}

@media screen and (max-width: 992px) {
    .wrapper__form_contact {
        margin: 5em 0;
    }
    .container__wrapper__form {
        flex-direction: column;
    }
    .container__contact-us {
        padding-top: 8em;
        padding-bottom: 3em;
    }
    .form__contact {
        width: 100%;
        padding: 0em 10%;
    }
    .wrapper__titile__contact {
        padding: 0 10%;
    }
    .container__contact-us__info {
        padding: 0 10%;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .container__wrapper__form {
        padding: 2em 0%;
    }
    .wrapper__form_contact {
        margin: 5em 7%;
    }
    .form__contact {
        padding: 0em 4em;
    }
}