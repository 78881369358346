.services-section {
  width: 100%;
}
.wrapper__swiper__services {
    padding: 2em 20%;
}
.mySwiper {
  width: 100%;
  height: 100%;
}

.mySwiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .wrapper__swiper__services {
    padding: 2em 3%;
    padding-bottom: 6em;
  }

  .wrapper__swiper__services {
    padding: 2em 2%;
  }

  .mySwiper .swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: 0;
  }

  .mySwiper .swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: 0;
  }
}


@media (min-width: 600px) and (max-width: 768px) {
  .wrapper__swiper__services {
    padding: 2em 5em;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .wrapper__swiper__services {
    padding: 2em 4%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .wrapper__swiper__services {
    padding: 2em 7.5em;
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .wrapper__swiper__services {
    padding: 2em 15em;
  }
}


