.rounded-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.6em;
    height: 2.6em;
    border-radius: 50%;
}
.rounded-icon:hover {
    opacity: .7;
}