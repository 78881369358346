.wrapper-particles {
  width: 100%!important;
  height: 100%!important;
}

.canvas-particles {
  background-image: url("./S2.svg");
  background-repeat: no-repeat;
  background-position: 210% 40%;
  background-size: 80%;
  position: absolute !important;
}

@media screen and (max-width: 600px) {
  .canvas-particles {
    height: 40em !important;
    background-image: none;
    background-position: initial;
    background-size: initial;
  }
}

@media (min-width: 600px) and (max-width: 992px) {
  .canvas-particles {
    height: 27em !important;
    background-image: none;
    background-position: initial;
    background-size: initial;
  }
}

@media (min-width: 992px) and (max-width: 1600px) {
  .canvas-particles {
    background-size: 120%;
    background-position: -100% 40%;
  }
}
