.about-section {
    width: 100%;
    height: 30em;
    position: relative;
}
.bg-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.wrapper__text__about {
    width: 100%;
    padding: 2em 28%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
}
.text__wrapper_about {
    text-align: center;
    color: var(--fourth-color);
    font-size: 1.4em;
}

@media screen and (max-width: 600px) {
    .about-section {
        background-position: left;
        background-size: 100vw 60em;
        height: 56em;
    }
    .wrapper__text__about {
        padding: 2em 9%;
    }
    .text__wrapper_about {
        font-size: 1.3em;
        padding-top: 2.5em;
    }
}

@media (min-width: 600px) and (max-width: 992px) {
    .about-section {
        background-position: left;
        background-size: 100vw 60em;
        height: 45em;
    }
    .wrapper__text__about {
        padding: 2em 20%;
    }
}
@media (min-width: 1200px) and (max-width: 1600px) {
    .about-section {
        height:30em;
    }
}

@media (min-width: 1600px) and (max-width: 2000px) {
    .about-section {
        height:40em;
    }
}