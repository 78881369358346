.container-title-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top:5em;
}

.title-section {
    text-align: center;
}
.line {
    width:9em;
    height: 2px!important;
    margin-bottom: 1em;
}