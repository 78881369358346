.footer-section {
    width: 100%;
}
.wrapper__info {
    width: 100%;
    text-align: center;
    padding-bottom: 1em;
}
.footer-bottom {
    background-color: var(--fourth-color);
    height: 5em;
    padding: 1.5em 10em;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.label-info-footer {
    color: var(--fourth-color);
    font-size:1.9em;
}
.name-company {
    font-weight: 500;
}
.link-to-init {
    cursor: pointer;
}
.links-footer {
    color: #fff;
    font-size: 1.4em;
    cursor: pointer;
}


@media screen and (max-width: 992px) {
    .wrapper__info {
        padding-bottom: 1.5em;
    }
    .footer-bottom {
        flex-direction: column;
        height: auto;
        padding: 2em 1%;
    }
    .links-footer {
        padding-bottom: .8em;
    }
}
    