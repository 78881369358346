.tech-section {
  margin-top: 4em;
  width: 100%;
  position: relative;
  margin-bottom: 12em;
}
.wrapper__swiper__tech {
  padding: 0em 25%;
  padding-top: 4em;
  position: absolute;
  left: 0;
  right: 0;
}
.swiper-tech {
  width: 100%;
  height: 100%;
  background-color: var(--fifth-color);
  border-radius: 20px;
  padding-bottom: 1.5em;
}

.swiper-tech .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--fifth-color);
}

.contanier-img-tech {
  width: 13em;
  height: 15em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 15px;
}
.img-tech {
  width: 7em;
  height: 7em;
  object-fit: contain;
}

.card-swiper-tech  {
  padding: 2em;
  background-color: #fff;
  border-radius: 20px;
}

.label-tech {
  font-size: 1.5em;
  font-weight: bold;
  color: var(--eighth-color);
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

@media screen and (max-width: 600px) {
  .wrapper__swiper__tech {
    padding: 2em 8%;
  }
  .swiper-tech {
    padding-bottom: 1.5em;
  }
  .swiper-tech .swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: 0;
  }

  .swiper-tech .swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: 0;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .wrapper__swiper__tech {
    padding: 2em 18%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .wrapper__swiper__tech {
    padding: 2em 5.5em;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .wrapper__swiper__tech {
    padding: 2em 7.5em;
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .wrapper__swiper__tech {
    padding: 2em 20%;
  }
}
