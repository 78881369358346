.hero-section {
    width: 100%;
    height: 32em;
    position: relative;
}
.container-text {
    padding-top: 9em;
    padding-left: 9em;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 50em;
}
.container-link-hero {
    position: absolute;
    top: 53%;
    right:20px;
    display: flex;
    flex-direction: column;
}
.text__wrapper {
    width: 70%;
    display: flex;
    flex-direction: column;
}
.text-welcome {
    color: var(--secondary-color);
}
.text-hero-section {
    padding-bottom: .7em;
    font-size: 1.2em;
}
.btn-hero-section {
    margin-top:2em;
}

@media screen and (max-width:600px) {
    .hero-section {
        height: 40em;
    }
    .container-text {
        padding: 0 1em;
        padding-top: 5em;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .container-link-hero {
        display: none;
    }

    .text__wrapper {
        width: 100%;
        align-items: center;
        padding-top: 1em;
    }
    .text-hero-section {
        text-align: center;
        font-size: 1.3em;
    }
}
@media (min-width:600px) and (max-width: 992px) {
    .hero-section {
        height: 27em;
    }
    .container-text {
        padding: 0 1em;
        padding-top: 5em;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .container-link-hero {
        display: none;
    }

    .text__wrapper {
        width: 100%;
        align-items: center;
    }
}