.btn-action {
    background-color: var(--third-color);
    color: #fff;
    height: 2.8em;
    font-weight: bold;
    border: 1px solid var(--third-color);
    border-radius: 12px;
    outline: none;    
}
.btn-action:hover { 
    opacity: .7;
}

.btn-action-anchor {
    background-color: var(--third-color);
    color: #fff;
    height: 2.8em;
    font-weight: bold;
    border: 1px solid var(--third-color);
    border-radius: 12px;
    outline: none; 
    text-decoration: none;
}