.card-testimonials {
  width: 28em;
  height: 25em;
}
.wrapper__img_testimonials {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-img-client {
  width: 80%;
  height: 8em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-client {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.wrapper__client {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.8em;
}
.client-name-label {
  color: var(--sixth-color);
  font-weight: bold;
  word-wrap: break-word;
  text-align: center;
}
.wrapper__project {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.5em;
}
.project-label {
  color: var(--sixth-color);
  word-wrap: break-word;
  text-align: center;
}
.wrapper__contact__client {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 0;
}
.btn-contact {
  padding: 0.4em 1.2em;
  background-color: var(--third-color);
  color: #fff;
  border: none;
  border-radius: 9px;
  font-size: 1em;
  cursor: pointer;
  transition: hover 0.3s ease-in-out;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
}

.btn-contact:hover {
  opacity: 0.8;
  color: #fff;
}
.wrapper__content {
  padding-top: 0.8em;
}
.quotes {
  width: 20px;
  height: 20px;
}
.rotate-quote {
  transform: rotate(180deg);
}
.wrapper__content {
  width: 100%;
  padding: 0 1.8em;
}


@media screen and (max-width: 600px) {
  .card-testimonials {
    width: 100%;
    height: 100%;
    padding-bottom: 1.5em;
  }
  .container-img-client {
    margin-top: 2em;
  }
  .wrapper__content {
    padding: 0 2.5em;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .card-testimonials {
    padding-bottom: 1.5em;
  }
  .wrapper__content {
    padding: 0 3  em;
  }
}
